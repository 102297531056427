import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const componentRepoStore = new PublicRepoStore("ApiComponentRepo", 1);

componentRepoStore.getters.getByVendorId = () => (vendorId) => {
  return store.state.apiPublic.client.endpoints.vendorComponents
    .getAll(vendorId)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      } else {
        return Promise.reject(
          `Failed to download component for vendor ${vendorId}`
        );
      }
    })
    .then((data) => {
      data.forEach((component) => {
        store.dispatch("apiPublicComponents/upsert", component);
      });

      return data;
    });
};

componentRepoStore.getters.getByItemId = () => (itemId) => {
  return store.state.apiPublic.client.endpoints.items
    .getComponents(itemId)
    .then((response) => {
      if (response.status == 200) {
        return response.data.data;
      } else {
        return Promise.reject(
          `Failed to download component for item ${itemId}`
        );
      }
    });
};

export default componentRepoStore;
