import PublicRepoStore from "./repoBase.js";
import store from "@/store";
import Enumerable from "linq";

const serviceComponentRepoStore = new PublicRepoStore(
  "ApiServiceComponentRepo",
  1
);

serviceComponentRepoStore.getters.getByService = () => async (serviceId) => {
  let response =
    await store.state.apiPublic.client.endpoints.serviceComponents.getAll(
      serviceId
    );

  if (response.status >= 200) {
    let data = response.data.data;

    Enumerable.from(data).select((serviceComponent) => {
      store.dispatch("repoServiceComponents/upsert", serviceComponent);
    });

    return data;
  }

  return [];
};

serviceComponentRepoStore.getters.getById =
  (state) => async (serviceId, componentId) => {
    return state.repo.getById(serviceId, componentId);
  };

export default serviceComponentRepoStore;
