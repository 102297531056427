import PublicRepoStore from "./repoBase.js";
import store from "@/store";
import Enumerable from "linq";

const serviceItemRepoStore = new PublicRepoStore("ApiServiceItemRepo", 1);

serviceItemRepoStore.getters.getById = (state) => async (serviceId, itemId) => {
  return state.repo.getById(serviceId, itemId);
};

serviceItemRepoStore.getters.getByService = () => async (serviceId) => {
  let response =
    await store.state.apiPublic.client.endpoints.serviceItems.getAll(serviceId);

  if (response.status >= 200) {
    let data = response.data.data;

    Enumerable.from(data).select((serviceItem) => {
      store.dispatch("repoServiceItems/upsert", serviceItem);
    });

    return data;
  }

  return [];
};

export default serviceItemRepoStore;
