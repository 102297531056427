import PublicRepoStore from "./repoBase.js";
import store from "@/store";

const menuRepoStore = new PublicRepoStore("ApiMenuRepo", 1);

menuRepoStore.mutations.SET_MENU = (state, menuId) => () => {
  state.menuId = menuId;
};

menuRepoStore.actions.loadMenu =
  ({ commit }, menuId) =>
  () => {
    const menu = store.state.repoMenus.getById(menuId);
    commit("SET_MENU", menuId);

    return menu;
  };

export default menuRepoStore;
